import { v4 as uuid4 } from 'uuid'

import { TAGS_OF_INTEREST } from '../../../app/constants'
import type {
  AppMetaFormData,
  ConfigTestCase,
  InteractedElement,
} from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import {
  EventGeneratorsEnum,
  ReactionTriggersEnum,
} from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import {
  APP_TAG_KEY,
  PROCESS_ID_REGEX_TITLE_END,
  PROCESS_ID_REGEX_TITLE_START,
  PROCESS_ID_REGEX_URL_PATH_END,
  PROCESS_ID_REGEX_URL_PATH_START,
  WINDOW_TAG_KEY,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/constants'
import type {
  DashboardContext,
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  ExtractIdentifierRule,
  MatchingCriteria,
  MatchingCriteriaContext,
  RawPatternType,
  Reaction,
  RuleIn,
  WebExtensionMatchingCriteria,
  WindowChangeTriggerRuleFormData,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { JsonObject } from '../../types/common'
import type { DataCollectionGranularityForm } from './types'

const appAndWindowNamesToTags = (appNames: string[], windowNames: string[]) => {
  const appTags = appNames.map((app) => ({ key: APP_TAG_KEY, value: app }))
  const windowTags = windowNames.map((window) => ({ key: WINDOW_TAG_KEY, value: window }))
  return [...appTags, ...windowTags]
}

export const getSalvageFields = (
  granularityData: DataCollectionGranularityForm | null | undefined,
) => {
  const salvageFields: string[] = []
  if (granularityData?.isTitleCaptured) salvageFields.push('title', 'title_lower')
  if (granularityData?.isUrlCaptured) salvageFields.push('url', 'url_parsed')
  if (granularityData?.isTypingCaptured) salvageFields.push('text_events')
  if (granularityData?.isClipboardContentCaptured)
    salvageFields.push('clipboard_copy_data', 'clipboard_paste_data')
  if (granularityData?.isKeyboardShortcutsCaptured) salvageFields.push('keyboard_shortcuts')
  if (granularityData?.isCopyPasteElementsCaptured) salvageFields.push('interacted_elements')

  salvageFields.sort()
  return salvageFields
}

export const generateConfigTestCase = (
  formData: DataCollectionTestCaseFormData,
  granularityData: DataCollectionGranularityForm,
  dashboardContext: DashboardContext,
) => {
  const appNames = dashboardContext.app_name ? [dashboardContext.app_name] : []
  const windowNames = dashboardContext.window_name ? [dashboardContext.window_name] : []

  return {
    test_id: uuid4(),
    rule_id: [],
    row_event: {
      title: formData.title,
      url: formData.url,
      active_process_name: formData.processName,
      ...getTestCaseInteractedElements(formData),
    },
    event_generators: [EventGeneratorsEnum.DEFAULT],
    force_tracking: false,
    expect_output: true,
    expected_salvage_fields: getSalvageFields(granularityData),
    expect_hashed_identifiers: false,
    expected_processed_event: {
      tags: appAndWindowNamesToTags(appNames, windowNames),
      extracted_identifiers:
        formData.processIdentifierName && formData.processIdentifierValue
          ? [
              {
                identifier_name: formData.processIdentifierName,
                value: formData.processIdentifierValue,
              },
            ]
          : [],
    },
    is_dashboard_generated: true,
    dashboard_context: dashboardContext,
  } as ConfigTestCase
}

const getTestCaseInteractedElements = (formData: DataCollectionTestCaseFormData) => {
  if (!formData.uiFieldKey || !formData.uiFieldValue) {
    return {}
  }

  const interactedElements = [
    {
      user_action: ReactionTriggersEnum.SWITCH_WINDOW,
      timestamp: new Date().toISOString(),
      extracted_data: [
        {
          key: formData.uiFieldKey,
          value: formData.uiFieldValue,
        },
      ],
    },
  ] as InteractedElement[]

  return { interacted_elements: interactedElements }
}

export const generateRuleIn = (
  ruleFormData: DataCollectionRuleFormData,
  granularityData: DataCollectionGranularityForm,
  dashboardContext: DashboardContext,
  uiFieldValues: DataCollectionFromUIElementRuleFormData | undefined = undefined,
): RuleIn => {
  const appNames = dashboardContext.app_name ? [dashboardContext.app_name] : []
  const windowNames = dashboardContext.window_name ? [dashboardContext.window_name] : []

  return {
    id: uuid4(),
    tags: appAndWindowNamesToTags(appNames, windowNames),
    allow_tracking: true,
    extract_identifiers: getExtractedidentifiers(ruleFormData),
    salvage_fields: getSalvageFields(granularityData),
    reactions: [...getReactionsFromGranularityData(granularityData)],
    matching_criteria: ruleFormDataToMatchingCriteria(ruleFormData, uiFieldValues),
    web_extension_matching_criteria: ruleFormDataToWebExtensionMatchingCriteria(ruleFormData),
    is_dashboard_generated: true,
    dashboard_context: dashboardContext,
  }
}

export const getUiFieldContextKeys = (uiFieldName: string) => {
  return { uiFieldContextKey: `${uiFieldName}_key_name`, uiFieldContextValues: `in_${uiFieldName}` }
}

const getExtractedidentifiers = (
  ruleFormData: DataCollectionRuleFormData,
): ExtractIdentifierRule[] => {
  const extractIdentifiers: ExtractIdentifierRule[] = []
  if (ruleFormData.processIdPathBefore && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromUrlPath(ruleFormData.processIdPathBefore, ruleFormData.processName),
    )
  }
  if (ruleFormData.processIdParamKey && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromUrlQuery(ruleFormData.processIdParamKey, ruleFormData.processName),
    )
  }
  if (ruleFormData.processIdTitleBefore && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromTitle(ruleFormData.processIdTitleBefore, ruleFormData.processName),
    )
  }

  return extractIdentifiers
}

const extractIdentifierFromUrlPath = (
  processIdPathBefore: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    from_fields: ['url'],
    regex_pattern: `${PROCESS_ID_REGEX_URL_PATH_START}${processIdPathBefore}${PROCESS_ID_REGEX_URL_PATH_END}`,
    regex_pattern_js: `${processIdPathBefore.replace('/', '\\/')}([^\\/\\?]+)`,
    compiled_regex: `${PROCESS_ID_REGEX_URL_PATH_START}${processIdPathBefore}${PROCESS_ID_REGEX_URL_PATH_END}`,
    key: null,
    hash_identifier: false,
  }
}

const extractIdentifierFromUrlQuery = (
  processIdParamKey: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    key: processIdParamKey,
    hash_identifier: false,
  }
}

const extractIdentifierFromTitle = (
  processIdTitleBefore: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    from_fields: ['title'],
    regex_pattern: `${PROCESS_ID_REGEX_TITLE_START}${processIdTitleBefore}${PROCESS_ID_REGEX_TITLE_END}`,
    regex_pattern_js: `${processIdTitleBefore.replace(' ', '\\s*')}(\\S+)`,
    compiled_regex: `${PROCESS_ID_REGEX_TITLE_START}${processIdTitleBefore}${PROCESS_ID_REGEX_TITLE_END}`,
    key: null,
    hash_identifier: false,
  }
}

const getReactionsFromGranularityData = (granularityData: DataCollectionGranularityForm) => {
  if (!granularityData.isCopyPasteElementsCaptured) return []

  return [
    {
      on: ['Paste'],
      react: [
        {
          Go: 'Focus',
        },
        {
          Extract: {
            Property: 'Name',
            As: 'paste_field_name',
          },
        },
      ],
    },
    {
      on: ['Copy'],
      react: [
        {
          Go: 'Focus',
        },
        {
          Extract: {
            Property: 'Name',
            As: 'copy_field_name',
          },
        },
      ],
    },
  ]
}

const updateReactionsFromGranularityData = (
  reactions: Reaction[],
  granularityData: DataCollectionGranularityForm,
) => {
  if (!granularityData.isCopyPasteElementsCaptured) {
    return reactions.filter((r) => r.on.length !== 1 || (r.on[0] !== 'Paste' && r.on[0] !== 'Copy'))
  }

  const hasCopyPasteReactions = reactions.some(
    (r) => r.on.length === 1 && (r.on[0] === 'Paste' || r.on[0] === 'Copy'),
  )

  return [
    ...reactions,
    ...(hasCopyPasteReactions ? [] : getReactionsFromGranularityData(granularityData)),
  ]
}

export const updateRuleGranularityData = (
  rule: RuleIn,
  granularityData: DataCollectionGranularityForm,
) => {
  const newSalvageFields = getSalvageFields(granularityData)

  return {
    ...rule,
    salvage_fields: newSalvageFields,
    reactions: updateReactionsFromGranularityData(rule.reactions, granularityData),
  }
}

export const updateAppTestCaseFromForm = (
  formData: DataCollectionTestCaseFormData,
  appData: AppMetaFormData,
  granularityData: DataCollectionGranularityForm,
  originaTestCase: ConfigTestCase,
) => {
  const generatedTestCase = generateConfigTestCase(formData, granularityData, {
    app_name: appData.appName,
  })
  // Keep original tags that are not used in app naming
  const nonAppNameTags =
    originaTestCase.expected_processed_event.tags?.filter(
      (tag) => !tag['key'] || tag['key'] !== APP_TAG_KEY,
    ) ?? []

  const combinedTags = [
    ...nonAppNameTags,
    ...(generatedTestCase.expected_processed_event.tags ?? []),
  ]

  return {
    ...originaTestCase,
    row_event: generatedTestCase.row_event,
    expected_salvage_fields: generatedTestCase.expected_salvage_fields,
    expected_processed_event: {
      ...originaTestCase.expected_processed_event,
      tags: combinedTags,
      extracted_identifiers:
        formData.processIdentifierName && formData.processIdentifierValue
          ? [
              {
                identifier_name: formData.processIdentifierName,
                value: formData.processIdentifierValue,
              },
            ]
          : [],
    },
    dashboard_context: { ...originaTestCase.dashboard_context, app_name: appData.appName },
  } as ConfigTestCase
}

const ruleEngineRuleTemplate = (
  contextKey: keyof MatchingCriteriaContext,
  fieldName: 'title_lower' | 'active_process_name_lower' | 'url',
) => {
  return `${fieldName} and not [w for w in context['${contextKey}'] if w not in ${fieldName}]`
}

const createUiFieldRules = (uiFieldValues: DataCollectionFromUIElementRuleFormData) => {
  const rules = [] as string[]
  const context = {} as MatchingCriteriaContext

  Object.entries(uiFieldValues).forEach(([uiFieldName, inValues]) => {
    const nonEmptyInValues = inValues.filter((v) => v && v.length > 0)
    if (nonEmptyInValues.length === 0) {
      return
    }

    const { uiFieldContextKey, uiFieldContextValues } = getUiFieldContextKeys(uiFieldName)

    context[uiFieldContextValues] = nonEmptyInValues
    context[uiFieldContextKey] = uiFieldName
    rules.push(createUiElementRule(uiFieldContextKey, uiFieldContextValues))
  })

  return { context, rules }
}

const createUiElementRule = (uiElementContextKey: string, uiElementContextValues: string) => {
  const checkThatAllKeywordsInValue = `not [w for w in context['${uiElementContextValues}'] if w not in d&['value']]`
  const checkIsMatchingExtractedData = `d&['key'] == context['${uiElementContextKey}'] and ${checkThatAllKeywordsInValue}`
  const checkIsMatchingInteractedElement = `[d for d in e&.extracted_data if ${checkIsMatchingExtractedData}]`
  const checkIsSwitchWindowTrigger = `e.user_action == '${ReactionTriggersEnum.SWITCH_WINDOW}'`
  return `[e for e in interacted_elements if ${checkIsSwitchWindowTrigger} and ${checkIsMatchingInteractedElement}]`
}

const ruleFormDataToMatchingCriteria = (
  ruleFormData: DataCollectionRuleFormData,
  uiFieldValues: DataCollectionFromUIElementRuleFormData | undefined = undefined,
): MatchingCriteria => {
  const ruleEngineRules: string[] = []
  let context: MatchingCriteriaContext = {}

  const cleanedUrlData = (ruleFormData.inUrl ?? []).filter(({ value }) => value)
  if (cleanedUrlData.length) {
    context.in_url = cleanedUrlData.map(({ value }) => value)
    ruleEngineRules.push(ruleEngineRuleTemplate('in_url', 'url'))
  }

  const cleanedTitleData = (ruleFormData.inTitle ?? []).filter(({ value }) => value)
  if (cleanedTitleData.length) {
    context.in_title = cleanedTitleData.map(({ value }) => value.toLowerCase())
    ruleEngineRules.push(ruleEngineRuleTemplate('in_title', 'title_lower'))
  }

  if (ruleFormData.inProcessName) {
    context.in_process_name = [ruleFormData.inProcessName.toLowerCase()]
    ruleEngineRules.push(ruleEngineRuleTemplate('in_process_name', 'active_process_name_lower'))
  }

  if (uiFieldValues && Object.keys(uiFieldValues).length > 0) {
    const { context: uiFieldContext, rules } = createUiFieldRules(uiFieldValues)
    ruleEngineRules.push(...rules)
    context = { ...context, ...uiFieldContext }
  }

  return {
    rule_engine_rule: ruleEngineRules.join(' and '),
    context,
  }
}

const ruleFormDataToWebExtensionMatchingCriteria = (
  ruleFormData: DataCollectionRuleFormData,
): WebExtensionMatchingCriteria => {
  const matchingCriteria: WebExtensionMatchingCriteria = {}

  const cleanedUrlData = (ruleFormData.inUrl ?? []).filter(({ value }) => value)
  if (cleanedUrlData.length) {
    matchingCriteria.in_url = cleanedUrlData.map(({ value }) => value)
  }

  const cleanedTitleData = (ruleFormData.inTitle ?? []).filter(({ value }) => value)
  if (cleanedTitleData.length) {
    matchingCriteria.in_title = cleanedTitleData.map(({ value }) => value.toLowerCase())
  }

  return matchingCriteria
}

export const updateRuleIn = (
  ruleFormData: DataCollectionRuleFormData,
  originalRule: RuleIn,
  uiFieldValues: DataCollectionFromUIElementRuleFormData | undefined = undefined,
): RuleIn => {
  return {
    ...originalRule,
    extract_identifiers: getExtractedidentifiers(ruleFormData),
    matching_criteria: ruleFormDataToMatchingCriteria(ruleFormData, uiFieldValues),
    web_extension_matching_criteria: ruleFormDataToWebExtensionMatchingCriteria(ruleFormData),
    dashboard_context: {
      ...originalRule.dashboard_context,
      process_name: ruleFormData.processName,
    },
  }
}

export const getAppNameFromTestCase = (testCase: ConfigTestCase): string => {
  if (!testCase.expected_processed_event['tags']) return 'Other'
  const tags = testCase.expected_processed_event['tags'] as JsonObject[]
  return tags
    .filter((tag) => tag['key'] && tag['key'] === APP_TAG_KEY)
    .map((tag) => tag['value'])
    .join(' | ')
}

export const getTestCaseLabel = (testCase: ConfigTestCase): string => {
  // Try to get app + window name from tags
  if (testCase.expected_processed_event.tags) {
    const windowTagValues = testCase.expected_processed_event.tags
      .filter((tag) => TAGS_OF_INTEREST.window.includes(tag.key) && tag.value)
      .map((tag) => tag.value)

    if (windowTagValues.length > 0) return windowTagValues.join(' | ')
  }

  if (testCase.description) return testCase.description

  return testCase.test_id
}

export const testCaseToFormData = (
  configTestCase: ConfigTestCase,
): DataCollectionTestCaseFormData => {
  const idName =
    configTestCase?.expected_processed_event?.extracted_identifiers?.[0]?.identifier_name
  const idValue = configTestCase?.expected_processed_event?.extracted_identifiers?.[0]?.value

  const rowEventIdentifier =
    configTestCase?.row_event?.interacted_elements &&
    (configTestCase.row_event.interacted_elements ?? []).length === 1
      ? configTestCase.row_event.interacted_elements[0]
      : null

  const extractedData =
    rowEventIdentifier?.extracted_data && rowEventIdentifier.extracted_data.length > 0
      ? rowEventIdentifier?.extracted_data[0]
      : null

  return {
    processName: configTestCase?.row_event?.active_process_name ?? '',
    url: configTestCase?.row_event?.url ?? '',
    title: configTestCase?.row_event?.title ?? '',
    processIdentifierName: idName ?? '',
    processIdentifierValue: idValue ?? '',

    uiFieldKey: extractedData?.key ?? '',
    uiFieldValue: extractedData?.value ?? '',
  }
}

export const windowChangeTriggerRuleToFormData = (
  ruleIn: RuleIn,
): WindowChangeTriggerRuleFormData => {
  const triggerName = ruleIn.dashboard_context?.window_change_trigger_name

  if (ruleIn.reactions.length === 0 || !triggerName) {
    return {
      triggerName: '',
      reaction: '',
    }
  }

  return {
    reaction: JSON.stringify(ruleIn.reactions[0].react),
    triggerName,
  }
}

export const windowChangeTriggerFormDataToRuleIn = (
  formData: WindowChangeTriggerRuleFormData,
  originalRuleIn: RuleIn,
): RuleIn => {
  return {
    ...originalRuleIn,
    dashboard_context: {
      ...originalRuleIn.dashboard_context,
      window_change_trigger_name: formData.triggerName,
    },
    reactions: createFullWindowChangeReaction(formData.reaction ?? '', formData.triggerName ?? ''),
  }
}

const createFullWindowChangeReaction = (reaction: string, triggerName: string): Reaction[] => {
  const DEFAULT_TRIGGERS = [ReactionTriggersEnum.SWITCH_WINDOW, ReactionTriggersEnum.TIMER10S]

  const userPattern = JSON.parse(reaction) as RawPatternType

  const lastStep = userPattern[userPattern.length - 1] as object
  if ('Extract' in lastStep) {
    const extractObj = lastStep['Extract'] as object
    userPattern[userPattern.length - 1] = {
      ...lastStep,
      Extract: { ...extractObj, As: triggerName },
    }
  }

  return [
    {
      on: DEFAULT_TRIGGERS,
      react: userPattern,
    },
    {
      on: [triggerName],
      react: [
        {
          Go: 'Foreground',
        },
        {
          Extract: {
            Property: 'Name',
            As: 'Name',
            ChangeWindowTrigger: '1',
          },
        },
      ],
    },
  ]
}
